import React from 'react';
import { useLocation } from 'react-router-dom';
import c from 'classnames';
import { useTranslation } from 'react-i18next';
import translationEn from './locales/en';
import translationEs from './locales/es';
import { SITE_URL } from 'containers/App/constants';

// Hooks
import { useWindowSize } from 'hooks/useWindowSize';

// Components
import ACTLink from 'components/ACTLink';
import Icon from 'components/Icon';
import Loading from 'components/Loading';
import ImgWithFallback from 'components/ImgWithFallback';

// Resources
import './NavMenu.scss';
import ACTHeaderIcons from 'assets/icons/genericIcons.svg';
const ACTIsotypeBG = `${SITE_URL}/assets/logos/ACTIsotype.svg`;

export default function NavMenu({ className, navOpen }) {
	const { pathname } = useLocation();
	const [windowWidth, windowHeight] = useWindowSize();
	const translationNameSpace = 'NavMenu';
	const { t, i18n } = useTranslation();
	if (!i18n.isInitialized) return <Loading />;

	if (
		i18n.isInitialized &&
		!i18n?.hasResourceBundle('en', translationNameSpace)
	) {
		i18n.addResources('en', translationNameSpace, translationEn);
		i18n.addResources('es', translationNameSpace, translationEs);
	}
	let style = {};
	if (navOpen && windowWidth <= 768 && windowHeight > 650) {
		style = {
			height: 'calc(100vh - 106px)',
		};
	} else if (navOpen && windowHeight <= 650) {
		style = {
			height: 'calc(100vh - 80px)',
		};
	}
	return (
		<nav
			className={className}
			title="Navigation Menu"
			aria-label="Navigation Menu"
			style={style}>
			<div className="NavMenu-body">
				<ImgWithFallback
					img={ACTIsotypeBG}
					type="image/svg+xml"
					className="NavMenu-bg-decoration"
					aria-hidden="true"
					focusable="false"
				/>
				<div className={c('NavMenu-link-wrapper', { active: pathname == '/' })}>
					<ACTLink
						className="NavMenu-link"
						href="/"
						accesibilityTitle={t(`${translationNameSpace}:home`)}
						Icon={(props) => (
							<Icon
								icons={ACTHeaderIcons}
								className="icon-header-home"
								id="home"
								{...props}
							/>
						)}
						visibleText={t(`${translationNameSpace}:home`)}
					/>
				</div>
				<div
					className={c('NavMenu-link-wrapper', {
						active: pathname == '/who-we-are' || pathname == '/nosotros',
					})}>
					<ACTLink
						className="NavMenu-link"
						accesibilityTitle={t(`${translationNameSpace}:who-we-are`)}
						href={i18n?.language === 'en' ? '/who-we-are' : '/nosotros'}
						Icon={(props) => (
							<Icon
								icons={ACTHeaderIcons}
								className="icon-header-aboutUs"
								id="aboutUs"
								{...props}
							/>
						)}
						visibleText={t(`${translationNameSpace}:who-we-are`)}
					/>
				</div>
				<div
					className={c('NavMenu-link-wrapper', {
						active: pathname == '/projects' || pathname == '/trabajos',
					})}>
					<ACTLink
						className="NavMenu-link"
						href={i18n?.language === 'en' ? '/projects' : '/trabajos'}
						accesibilityTitle={t(`${translationNameSpace}:projects`)}
						Icon={(props) => (
							<Icon
								icons={ACTHeaderIcons}
								className="icon-header-jobs"
								id="jobs"
								{...props}
							/>
						)}
						visibleText={t(`${translationNameSpace}:projects`)}
					/>
				</div>
				<div
					className={c('NavMenu-link-wrapper', {
						active: pathname == '/services' || pathname == '/servicios',
					})}>
					<ACTLink
						className="NavMenu-link"
						href={i18n?.language === 'en' ? '/services' : '/servicios'}
						accesibilityTitle={t(`${translationNameSpace}:services`)}
						Icon={(props) => (
							<Icon
								icons={ACTHeaderIcons}
								className="icon-header-services"
								id="services"
								{...props}
							/>
						)}
						visibleText={t(`${translationNameSpace}:services`)}
					/>
				</div>
				<div
					className={c('NavMenu-link-wrapper', {
						active: pathname == '/contacto' || pathname == '/contact-us',
					})}>
					<ACTLink
						className="NavMenu-link"
						href={i18n?.language === 'en' ? '/contact-us' : '/contacto'}
						accesibilityTitle={t(`${translationNameSpace}:contact`)}
						Icon={(props) => (
							<Icon
								icons={ACTHeaderIcons}
								className="icon-header-contact"
								id="contact"
								{...props}
							/>
						)}
						visibleText={t(`${translationNameSpace}:contact`)}
					/>
				</div>
				<div
					className={c('NavMenu-link-wrapper', {
						active:
							pathname == '/trabaja-con-nosotros' ||
							pathname == '/work-with-us',
					})}>
					<ACTLink
						className="NavMenu-link"
						href={
							i18n?.language === 'en'
								? '/work-with-us'
								: '/trabaja-con-nosotros'
						}
						accesibilityTitle={t(`${translationNameSpace}:work-with-us`)}
						Icon={(props) => (
							<Icon
								icons={ACTHeaderIcons}
								className="icon-header-workWithUs"
								id="workWithUs"
								{...props}
							/>
						)}
						visibleText={t(`${translationNameSpace}:work-with-us`)}
					/>
				</div>
			</div>

			<div className="NavMenu-footer">
				<div className="contactInfo">
					<ACTLink
						className="NavMenu-link contactPhone"
						Icon={(props) => (
							<Icon
								icons={ACTHeaderIcons}
								className="icon-header-whatsApp"
								id="whatsApp"
								{...props}
							/>
						)}
						href="tel:+59892548610"
						accesibilityTitle="ACTotal Mobile Phone"
						visibleText="(+598) 92 548 610"
					/>
					<ACTLink
						className="NavMenu-link contactEmail"
						Icon={(props) => (
							<Icon
								icons={ACTHeaderIcons}
								className="icon-header-email"
								id="email"
								{...props}
							/>
						)}
						href="mailto:info@actotal.com"
						accesibilityTitle="ACTotal Email"
						visibleText="info@actotal.com"
					/>
					<p>
						© 2025 ACTotal. <span>All Rights Reserved.</span>
					</p>
				</div>
				<div className="socialNetworks">
					<ACTLink
						className="NavMenu-link socialFacebook"
						Icon={(props) => (
							<Icon
								icons={ACTHeaderIcons}
								className="icon-header-facebook"
								id="facebook"
								{...props}
							/>
						)}
						href="https://www.facebook.com/actotaltech/"
						accesibilityTitle="ACTotal Facebook"
						target="_blank"
					/>
					<ACTLink
						className="NavMenu-link socialLinkedIn"
						Icon={(props) => (
							<Icon
								icons={ACTHeaderIcons}
								className="icon-header-linkedin"
								id="linkedin"
								{...props}
							/>
						)}
						href="https://www.linkedin.com/company/actotaltech"
						accesibilityTitle="ACTotal LinkedIn"
						target="_blank"
					/>
					<ACTLink
						className="NavMenu-link socialInstagram"
						Icon={(props) => (
							<Icon
								icons={ACTHeaderIcons}
								className="icon-header-instagram"
								id="instagram"
								{...props}
							/>
						)}
						href="https://www.instagram.com/actotal_tech"
						accesibilityTitle="ACTotal Instagram"
						target="_blank"
					/>
					<ACTLink
						className="NavMenu-link socialTwitter"
						Icon={(props) => (
							<Icon
								icons={ACTHeaderIcons}
								className="icon-header-twitter"
								id="twitter"
								{...props}
							/>
						)}
						href="https://twitter.com/actotal_tech"
						accesibilityTitle="ACTotal Twitter"
						target="_blank"
					/>
				</div>
			</div>
		</nav>
	);
}
